//import 'sylius/bundle/ShopBundle/Resources/private/entry';
//import 'sylius/bundle/ShopBundle/Resources/private/entry';
//import $ from '../js/jquery/jquery'
import 'sylius/bundle/ShopBundle/Resources/private/js/shim/shim-jquery';
import 'sylius/bundle/ShopBundle/Resources/private/js/shim/shim-lightbox';
import 'sylius/bundle/ShopBundle/Resources/private/js/shim/shim-semantic-ui';

import 'semantic-ui-css/semantic.css';
//import '../css/semanticui.css';
import 'lightbox2/dist/css/lightbox.min.css';
import 'slick-carousel/slick/slick.css';

import 'sylius/ui-resources/js/app';
//import 'sylius/bundle/ShopBundle/Resources/private/js/app';
/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import 'semantic-ui-css/components/popup';
import 'semantic-ui-css/components/rating';
import $ from 'jquery';
import 'slick-carousel';



import 'sylius/bundle/UiBundle/Resources/private/js/app';
import 'sylius/bundle/UiBundle/Resources/private/js/sylius-api-login';
import 'sylius/bundle/UiBundle/Resources/private/js/sylius-api-toggle';



import 'sylius/bundle/ShopBundle/Resources/private/js/sylius-add-to-cart';
import 'sylius/bundle/ShopBundle/Resources/private/js/sylius-address-book';
import 'sylius/bundle/ShopBundle/Resources/private/js/sylius-province-field';
//import 'sylius/bundle/ShopBundle/Resources/private/js/sylius-variant-images';
//import 'sylius/bundle/ShopBundle/Resources/private/js/sylius-variants-prices';



$(document).ready(() => {
  $('.popup-js').popup();

  $('.cart.button')
    .popup({
      popup: $('.cart.popup'),
      on: 'click',
    });

  $('.star.rating').rating({
    fireOnInit: true,
    onRate(value) {
      $('[name="sylius_product_review[rating]"]:checked').removeAttr('checked');
      $(`#sylius_product_review_rating_${value - 1}`).attr('checked', 'checked');
    },
  });

  $('#sylius_checkout_address_customer_email').apiToggle({
    dataType: 'json',
    method: 'GET',
    throttle: 1500,

    beforeSend(settings) {
      const email = $('#sylius_checkout_address_customer_email').val();

      if (email.length < 3) {
        return false;
      }

      /* eslint-disable-next-line no-param-reassign */
      settings.data = {
        email,
      };

      return settings;
    },

    successTest(response) {
      return $('#sylius_checkout_address_customer_email').val() === response.username;
    },
  }, $('#sylius-api-login-form'));

  $('#sylius-api-login').apiLogin({
    method: 'POST',
    throttle: 500,
  });

  $('#sylius-product-adding-to-cart').addToCart();

  $('#sylius-shipping-address').addressBook();
  $('#sylius-billing-address').addressBook();
  $(document).provinceField();
  //$(document).variantPrices();


  $('body').find('input[autocomplete="off"]').prop('autocomplete', 'disable');

  $('.carousel').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: $('.carousel-left'),
    nextArrow: $('.carousel-right'),
    appendArrows: false
  });
});



import 'sylius/ui-resources/sass/main.scss';
import 'sylius/bundle/ShopBundle/Resources/private/scss/style.scss';

import 'sylius/ui-resources/img/logo.png';
import 'sylius/ui-resources/img/avatar.png';

import 'sylius/bundle/ShopBundle/Resources/private/img/homepage-banner.jpg';
import 'sylius/bundle/ShopBundle/Resources/private/img/sylius-plus-banner.png';


//import '../js/avia-footer-scripts'

import './ShopWidget';
import '../styles/app.scss'


