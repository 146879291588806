/**
 * Created by tk on 11.04.16.
 */
var moment = require("moment");
window.moment = moment;
import 'jquery-ui';
import './InstagramFeed.min'

(function ($, window, document, undefined) {

  $.widget("studio201.ShopWidget",
    { // >  StudyWidget options ##################################################
      options: {},
      _create: function () {
        var w = this, e = w.element, o = w.options, b = $('body');

      },
      _init: function () {
        var w = this, e = w.element, o = w.options, b = $('body');


        $(window).on('load', function () {
          $("#instafeed").each(function () {
            $.instagramFeed({
              'username': 'marryandlilo',
              'container': $(this),
              'display_profile': false,
              'display_biography': false,
              'display_gallery': true,
              'display_captions': false,
              'styling': true,
              'items': 6,
              'items_per_row': 6,
              'margin': 1,
              'lazy_load': true,
              'on_error': console.error,
              'callback': function () {
                $("#instafeed").find("img").each(function () {
                  //$(this).css("height", $(this).css("width"))
                  $(this).css("height", '100%');
                });
              }
            });

          });
        });

        $(window).on('resize', function () {
          $("#sbi_images").find("img").each(function () {
            $(this).css("height", $(this).css("width"))
          });
        });

        w._initVariantDescriptions();

        w._variantImages();
      },

      _variantImages: function () {
        var w = this, e = w.element, o = w.options, b = $('body');
        if ($('[data-variant-options]').length > 0) {
          w._handleProductOptionImages();
          w._handleProductOptionChange();
        } else if ($('[data-variant-code]').length > 0) {
          w._handleProductVariantImages($('[name="sylius_add_to_cart[cartItem][variant]"]'));
          w._handleProductVariantChange();
        } else if ($('[data-customeroption-code]').length > 0) {
          w._handleProductVariantImages($('[name="sylius_add_to_cart[cartItem][variant]"]'));
          w._handleProductVariantChange();
        }
      },

      _initVariantDescriptions: function () {
        var w = this, e = w.element, o = w.options, b = $('body');

        $("#sylius-product-variants").each(function () {
          $(this).on("change", function () {
            w._changeVariantDescriptions($(this));

            var priceString = $(this).find("option:selected").attr("data-price");
            priceString = priceString.replace("€", "").replace(",", "").replace(".", "").trim();
            var price = parseInt(priceString);
            $("#product-price").data("standard-price", price)
            w._calculateProductPrice();
          });
          w._changeVariantDescriptions($(this));

        });

      },

      _changeMainImage: function changeMainImage(newImageDiv) {
        var mainImageLink = $('a.ui.fluid.image');
        var mainImage = $('a.ui.fluid.image > img');

        var newImage = $(newImageDiv).find('img');
        var newImageLink = $(newImageDiv).find('a');

        if (newImage.length === 0 && newImageLink.length === 0) {
          mainImage.attr('src', $('div[data-product-image]').attr('data-product-image'));
          newImageLink.attr('href', $('div[data-product-link]').attr('data-product-link'));

          return;
        }
        console.log("before" + $(mainImage).attr('src'))
        var href2 = newImageLink.attr('href');
        var img2 = newImage.attr('data-large-thumbnail');
        mainImageLink.attr('href', href2);
        $(mainImage).attr('src', img2);
        mainImageLink.find("img").attr("src", img2);
        console.log("after" + $(mainImage).attr('src'))
      },
      _handleProductOptionImages: function handleProductOptionImages() {
        var w = this, e = w.element, o = w.options, b = $('body');
        let options = '';

        $('#sylius-product-adding-to-cart select').each((index, select) => {
          options += `${$(select).find('option:selected').val()} `;
        });

        const imagesWithOptions = [];
        const optionsArray = options.trim().split(' ');

        $('[data-variant-options]').each((index, element) => {
          const imageOptions = $(element).attr('data-variant-options');
          const imageHasOptions = optionsArray.every(option => imageOptions.indexOf(option) > -1);

          if (imageHasOptions) {
            imagesWithOptions.push($(element).closest('div.ui.image'));
          }
        });

        w._changeMainImage(imagesWithOptions.shift());
      },
      _handleProductOptionChange() {
        var w = this, e = w.element, o = w.options, b = $('body');
        $('[name*="sylius_add_to_cart[cartItem][variant]"]').on('change', () => {
          w._handleProductOptionImages();
        });
      },
      _handleProductVariantChange: function () {
        var w = this, e = w.element, o = w.options, b = $('body');
        $('[name="sylius_add_to_cart[cartItem][variant]"]').on('change', (event) => {
          w._handleProductVariantImages($(event.currentTarget));
        });
      },
      _handleProductVariantImages: function (variantElement) {
        var w = this, e = w.element, o = w.options, b = $('body');
        var variantCode = $(variantElement).attr('value');
        if (variantCode == undefined) {
          variantCode = $(variantElement).find(":selected").val();
        }
        if (variantCode != undefined) {
          var variantList = [];
          variantList.push(variantCode);
          w._filterCustomImages(variantList);
        }

      },

      _filterCustomImages: function (variantList, variantSelector) {
        var w = this, e = w.element, o = w.options, b = $('body');


         if (variantSelector == undefined) {
          variantSelector = "data-variant-code";
        }
        // const variantCode = $(variantElement).attr('value');

        const imagesWithVariantCode = [];
        for (var varId in variantList) {
          var variantCode = variantList[varId];
          var currentSelector = '[' + variantSelector + '*="' + variantCode + '"]'
          $(currentSelector).each((index, element) => {
              imagesWithVariantCode.push($(element).closest('div.ui.image'));
          });
        }
        w._changeMainImage(imagesWithVariantCode.shift());
      },
      _changeVariantDescriptions: function (select) {
        var parent = select.parent();
        var description = parent.find(".variant-description");
        if (description.length == 0) {
          description = $("<div class='variant-description'/>");
          description.insertAfter(select);
        }
        var selectedElement = select.find(":selected");
        var descriptionText = selectedElement.attr("data-description");
        var count = selectedElement.attr("data-count");
        if (descriptionText != undefined && descriptionText != null) {
          var descriptionText = descriptionText.split("###").join('"')
          if (count != undefined) {
            count = parseInt(count);
            if (count == 0) {
              descriptionText += '<br/><p class="stock out-of-stock">Nicht vorrätig</p>';
            } else {
              descriptionText += '<br/><p class="stock out-of-stock">' + count + ' vorrätig</p>';
            }
          }
          description.html(descriptionText);
        }

      },

      _initPopup: function () {
        var w = this, e = w.element, o = w.options, b = $('body');
        $('.js-cart.button').each(function () {
          var parent = $(this);
          if (parent.is(":visible")) {
            parent.addClass("popup_inited");
            $(this).popup({
              popup: parent.find('.cart.popup'),
              on: 'click'
            });
          }

        });
      },
      _calculateProductPrice: function (variantPrice) {
        var w = this, e = w.element, o = w.options, b = $('body');
        //product-price
        var priceElement = $("#product-price");
        var currency = priceElement.data("standard-currency");
        var standardPrice = priceElement.data("standard-price");
        if (variantPrice != undefined) {
          standardPrice = variantPrice;
        }
        var amount = $("#sylius_add_to_cart_cartItem_quantity").val();
        var standardPrice = amount * parseInt(standardPrice) / 100;
        $("#sylius_add_to_cart_customer_options").find("input").each(function () {
          var optionAmount = $(this).data("price-amount");
          var optionMultiplyWith = $(this).data("multiply-with");
          if ($(this).is(':checked') && optionAmount != undefined) {
            if (optionMultiplyWith != undefined && optionMultiplyWith != "") {
              var multiplier = $("#sylius_add_to_cart_customer_options_" + optionMultiplyWith).find("option:selected").html()
              if (parseInt(multiplier) > 0) {
                standardPrice += parseInt(parseInt(multiplier) * optionAmount) / 100;
              } else {
                standardPrice += parseInt(optionAmount) / 100;
              }
            } else {
              standardPrice += parseInt(optionAmount) / 100;
            }


          }
        });
        $("#sylius_add_to_cart_customer_options").find("select option").each(function () {
          var optionAmount = $(this).data("price-amount");
          if ($(this).is(':selected') && optionAmount != undefined) {
            standardPrice += parseInt(optionAmount) / 100;
          }
        });
        //DE:  var priceString = standardPrice.toFixed(2).replace(".", ",") + " " + currency;
        var priceString = currency+""+standardPrice.toFixed(2);

        priceElement.html(priceString);
      },
      _initContactform: function () {
        var w = this, e = w.element, o = w.options, b = $('body');
        $(".js-contact").not(".js-contact_inited").addClass("js-contact_inited").each(function () {
          var contactForm = $(this);

          $(this).find(".js-submit").on("click", function (ev) {
            ev.preventDefault();
            var data = new FormData();
            contactForm.find("input, textarea").each(function () {
              data.append($(this).attr("name"), $(this).val());
              //$(this).val("");
            });
            var routeName = contactForm.data("route");
            var url = Routing.generate(routeName, {});
            $.ajax({
              url: url,
              type: "POST",
              data: data,
              processData: false,
              contentType: false,
              success: function (result, textStatus, jqXHR) {
                var errorsFound = false;
                $(result).find(".errors").each(function () {
                  if ($(this).find("li").length > 0) {
                    errorsFound = true;
                  }
                });
                if (errorsFound) {
                  $(".js-contact").replaceWith($(result));
                  w._initContactform();
                } else {
                  $(".js-contact").remove();
                }

              },
              error: function (jqXHR, textStatus, errorThrown) {
                if (typeof errorCallback == "function") {
                  errorCallback($item, errorThrown);
                }
              }
            });

            return false;
          });
        });
      },

      _wp: function () {
        var a = {offset: "bottom-in-view", triggerOnce: !0}, t = i.extend({}, a, e);
        return this.each(function () {
          var e = i(this);
          setTimeout(function () {
            if (n) {

            } else {
              e.waypoint(function (e) {
                var t = i(this.element), a = t.parents(".av-animation-delay-container:eq(0)");
                if (a.length) {
                  t.addClass("avia_start_animation_when_active").trigger("avia_start_animation_when_active")
                }
                ;
                if (!a.length || (a.length && a.is(".__av_init_open")) || (a.length && a.is(".av-active-tab-content"))) {
                  t.addClass("avia_start_animation").trigger("avia_start_animation")
                }
              }, t)
            }
          }, 100)
        });
      },
      _enableSlider: function (parent) {
        var w = this, e = w.element, o = w.options, b = $('body');
        var arrows = $("<div class='arrows'/>");

        parent.parent().append(arrows);

        parent.on("init", function (event, slick, currentSlide) {
          w._checkSlideButtons(slick)
        })

        o.OPTIONSLIDER = parent.slick({
          infinite: false,
          slide: "div.field",
          adaptiveHeight: true,
          responsive: true,
          prevArrow: '<button class="slick-prev ui  primary button" aria-label="Zurück" type="button">Zurück</button>',
          nextArrow: '<button class="slick-next ui  primary button right floated" aria-label="Weiter" type="button">Weiter</button><span style="clear:both">&nbsp;</span>',
          appendArrows: arrows
        })
          .on("afterChange", function (event, slick, currentSlide) {
            w._checkSlideButtons(slick)
          });
      },
      _checkCanCheckout: function () {
        var w = this, e = w.element, o = w.options, b = $('body');
        var fields = $("#sylius_add_to_cart_customer_options").closest("form").find(".field");
        var canCheckout = fields.length > 0;
        fields.each(function () {
          if ($(this).hasClass("cal")) {
            if ($(this).find(".errormsg").length > 0) {
              canCheckout = canCheckout && false;
            }
          } else {
            $(this).find("input[required],textarea[required],select[required]").each(function () {
              var that = $(this);
              var val = that.val();
              var type = this.type;
              if (type == "radio") {
                val = $("input:radio[name='" + that.attr("name") + "']:checked").val();
              }

              if (val == "" || val == undefined) {
                canCheckout = canCheckout && false;
              }
            });
          }
        });
        if (o.CALENDAR.attr("data-disabled") == "true") {
          return false;
        }

        return canCheckout;
      },
      _checkSlideButtons(slick) {
        var w = this, e = w.element, o = w.options, b = $('body');
        var current = slick.currentSlide;
        var size = slick.slideCount;
        console.log("slick" + (current + 1) + " / " + size);
        var currentElement = slick.$slides[current];
        var unfilledRequired = false;

        $(currentElement).find("input[required],textarea[required],select[required]").each(function () {
          var that = $(this);
          var val = that.val();
          var type = this.type;
          if (type == "radio") {
            val = $("input:radio[name='" + that.attr("name") + "']:checked").val();
          }

          if (val == "" || val == undefined) {
            unfilledRequired = true;
          }
        });


        if (current == 0) {
          $(".slick-prev").hide();
        } else {
          $(".slick-prev").show();
        }
        if (current + 1 == size || unfilledRequired) {
          $(".slick-next").hide();

        } else {
          $(".slick-next").show();

        }
        if (w._checkCanCheckout()) {
          w._enableCartButton();
        } else {
          w._disableCartButton();
        }
      },


      _enableCartButton: function () {
        var w = this, e = w.element, o = w.options, b = $('body');
        var cartButton = b.find('form[name="sylius_add_to_cart"]').find('button[type="submit"]');
        cartButton.show();
      },
      _disableCartButton: function () {
        var w = this, e = w.element, o = w.options, b = $('body');
        var cartButton = b.find('form[name="sylius_add_to_cart"]').find('button[type="submit"]');
        cartButton.hide();
      },
      _initDateTimePicker: function (field, dateItem, timeItem, personsItem) {
        var w = this, e = w.element, o = w.options, b = $('body');
        field.css("display", "none");
        field.removeClass("field");
        field.closest("form").find("#sylius_add_to_cart_cartItem_quantity").parent().css("display", "none");
        w._disableCartButton();
        o.CALENDAR = $("<div class='cal field'/>");
        o.CALENDAR.insertAfter(field);
        var tomorrow = moment().add(1, 'days').set('hours', 0);
        o.CALENDAR.datetimepicker({
          inline: true,
          format: "MM/dd/YYYY HH:mm",
          minDate: tomorrow,
          locale: 'de',
          sideBySide: true,

          // disabledTimeIntervals: [[moment({h: 0}), moment({h: 8})], [moment({h: 18}), moment({h: 24})]],
          //enabledHours: [9, 10, 11, 12, 13, 14, 15, 16],
          icons: {
            previous: 'fa fa-arrow-left',
            next: 'fa fa-arrow-right',
          },

          stepping: 60
        }).on('dp.update', function (e, data) {
          var datesNew = w._getValidDates(e.viewDate);
          var params = datesNew;
          params["code"] = code;
          w._updateCalendarDays(params);

        }).on('dp.change', function (e, data) {


          var datesNew = w._getValidDates(e.date);
          var params = datesNew;
          params["code"] = code;
          w._updateCalendarDays(params, e.date);

          var date = new Date(e.date);

          params = {
            "currentDate": date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear(),
            "code": code
          };
          w._updateCalendarHours(params, e.date, personsItem);
          w._updateDateSelect(dateItem, date);
          w._updateTimeSelect(timeItem, date);
        });
        ;
        //$(".timepicker .timepicker-picker td .timepicker-hour")
        //$(".timepicker .timepicker-picker td .timepicker-minute").css("width", "80%").parent().append(" Uhr")
        $(".timepicker-hour, .timepicker-minute").on("click", function () {
          setTimeout(function () {
            o.OPTIONSLIDER.slick('setPosition');
            o.OPTIONSLIDER.slick('resize');
            $(".hour, .minute").on("click", function () {
              setTimeout(function () {
                o.OPTIONSLIDER.slick('setPosition');
                o.OPTIONSLIDER.slick('resize');

              }, 100);
            });


          }, 100);
        });
        var dates = w._getValidDates(moment());
        var code = dateItem.attr("id").replace("_date", "").replace("sylius_add_to_cart_customer_options_", "");

        var params = dates;
        params["code"] = code;
        w._updateCalendarDays(params);

        var date = new Date();
        var params = {
          "currentDate": date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear(),
          "code": code
        };
        w._updateCalendarHours(params, undefined, personsItem);
        w._updateDateSelect(dateItem, date);
        w._updateTimeSelect(timeItem, date);


      },

      _updateDateSelect: function (dateElement, date) {
        var w = this, e = w.element, o = w.options, b = $('body');
        var day = "" + date.getDate();

        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        dateElement.find("select").each(function () {
          var id = $(this).attr("id");
          if (id.endsWith("_day")) {
            var currentSelect = $("#" + id);
            currentSelect.val(day).prop('selected', true);

          }
          if (id.endsWith("_month")) {
            var currentSelect = $("#" + id);
            currentSelect.val(month).prop('selected', true);
          }
          if (id.endsWith("_year")) {
            var currentSelect = $("#" + id);
            currentSelect.val(year).prop('selected', true);
          }
        });

      },

      _updateTimeSelect: function (timeElement, date) {
        var w = this, e = w.element, o = w.options, b = $('body');
        var hour = date.getHours();
        var minute = date.getMinutes();

        timeElement.find("select").each(function () {
          var id = $(this).attr("id");
          if (id.endsWith("_hour")) {
            var currentSelect = $("#" + id);
            currentSelect.val(hour).prop('selected', true);
          }
          if (id.endsWith("_minute")) {
            var currentSelect = $("#" + id);
            currentSelect.val(minute).prop('selected', true);
          }
        });

      },

      _showLoader: function (container) {
        var w = this, e = w.element, o = w.options, b = $('body');
        var loader = '<div class="ui active inverted dimmer">\
        <div class="ui text loader">Loading</div>\
        </div>'
        container.addClass("hidden");
        $(loader).insertBefore(container);
      },

      _hideLoader: function (container) {
        var w = this, e = w.element, o = w.options, b = $('body');
        var loader = '<div class="ui active inverted dimmer">\
        <div class="ui text loader">Loading</div>\
        </div>'
        container.removeClass("hidden");
        container.parent().find(".dimmer").remove();
      },

      _updateCalendarDays: function (params, selectedDate) {
        var w = this, e = w.element, o = w.options, b = $('body');
        var route = Routing.generate(o.WORKSHOP_API_ROUTE, {});
        params["productCode"] = o.PRODUCTCODE;
        //w._showLoader(o.CALENDAR);
        $.ajax({
          url: route,
          data: params,
          cache: false,
          processData: true,
          type: 'POST',
          success: function (value) {
            var events = value["events"];
            var type = value["type"];
            var error = value["error"];
            var parentControl = o.CALENDAR.parent().parent();
            //var errorControl = parentControl.parent().find(".errormsg")
            var errorControl = o.CALENDAR.find(".errormsg");
            if (error != "") {
              o.CALENDAR.prop('disabled', true);
              if (errorControl.length == 0) {
                errorControl = $("<h3 style='color:red;text-align: center;' class='errormsg'></h3>");
                //errorControl.insertAfter(parentControl);
                o.CALENDAR.append(errorControl)
              }
              errorControl.text(error)
              $(".timepicker").css("display", "none");
              w._hideLoader(o.CALENDAR);
              w._disableCartButton();

              if (o.LASTAVAILABLEDATES.length > 0) {

                o.CALENDAR.data("DateTimePicker").enabledDates(o.LASTAVAILABLEDATES);
              } else {
                o.CALENDAR.data("DateTimePicker").enabledDates([moment("1970-01-01")]);
                o.CALENDAR.data("DateTimePicker").enabledHours([0]);
              }

              //o.CALENDAR.data("DateTimePicker").disabledHours([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]);


            } else {
              if (errorControl.length > 0) {
                $(".timepicker").css("display", "visible");
                errorControl.remove();
              }
              if (type == "blocked") {
                var blockedDates = [];
                for (var id in events) {
                  blockedDates.push(moment(events[id]));
                }
                o.CALENDAR.data("DateTimePicker").disabledDates(blockedDates);
                w._hideLoader(o.CALENDAR);
              } else if (type == "available") {
                var availableDates = [];
                for (var id in events) {
                  var startdate = events[id]["startdate"];
                  var enddate = events[id]["enddate"];
                  var remainingparticipants = events[id]["remainingparticipants"];
                  //if (remainingparticipants > 0) {
                  availableDates.push(moment(startdate));
                  //}

                }
                if (selectedDate != undefined) {
                  o.CALENDAR.data("DateTimePicker").defaultDate(selectedDate);
                } else if (availableDates.length > 0) {
                  o.CALENDAR.data("DateTimePicker").defaultDate(availableDates[0]);

                }
                o.CALENDAR.data("DateTimePicker").enabledDates(availableDates);
                o.LASTAVAILABLEDATES = availableDates;
                w._hideLoader(o.CALENDAR);
              }
            }
            if (o.INITIALDATESET == false) {
              var picker = o.CALENDAR.data("DateTimePicker");
              setTimeout(function () {
                var defaultDate = picker.defaultDate();
                var currentDate = new Date(picker.date());
                w._updateDateSelect(o.DATESELECT, currentDate);
                w._updateTimeSelect(o.TIMESELECT, currentDate);
                if ($(".day.active.disabled").length > 0) {
                  $(".timepicker .timepicker-picker").hide();
                } else {
                  $(".timepicker .timepicker-picker").show();
                }

                o.OPTIONSLIDER.slick('setPosition');
                o.OPTIONSLIDER.slick('resize');

              }, 100)

            }


          }
        });
      },

      _updateCalendarHours: function (params, selectedDate, personsItem) {
        var w = this, e = w.element, o = w.options, b = $('body');
        var route = Routing.generate(o.WORKSHOP_API_ROUTE, {});
        //w._showLoader(o.CALENDAR.find(".timepicker"));
        params["productCode"] = o.PRODUCTCODE;
        $.ajax({
          url: route,
          data: params,
          cache: false,
          processData: true,
          type: 'POST',
          success: function (value) {
            var type = value["type"];
            //if (o.CALENDAR.is(":visible")) {
            var minPersons = 0;
            if (value["min_number_participants"]) {
              minPersons = parseInt(value["min_number_participants"]);
            }
            if (type == "blocked") {
              var blockedHours = value["hours"];

              o.CALENDAR.data("DateTimePicker").disabledHours(blockedHours);
              o.CALENDAR.data("DateTimePicker").stepping(30);

              if (personsItem != false) {
                personsItem.find("option").each(function () {
                  var optionValue = $(this).val();
                  optionValue = parseInt(optionValue.replace("personcount_", ""));
                  if (optionValue < minPersons) {
                    $(this).remove();
                    this.selected = false;
                  }
                });
              }
            } else {

              var availableHours = []//= value["hours"];
              var fullHours = [];
              var events = value["events"];
              var remainingparticipants = 0;
              var remainingByHour = {};
              var error = "";
              for (var id in events) {
                var event = events[id];
                var starttime = moment(event["startdate"]);
                var hours = starttime.hours();
                if (event["remainingparticipants"] > 0) {
                  availableHours.push(hours);
                  remainingByHour[hours] = event["remainingparticipants"];
                } else {
                  fullHours.push(hours);
                  error += hours + " Uhr - Keine freien Plätze verfügbar<br/>";
                }


              }


              if (selectedDate != undefined && availableHours.length > 0) {
                var currentHour = selectedDate.hours();

                if (availableHours.includes(currentHour) == false) {
                  currentHour = availableHours[0];
                  selectedDate.set({hour: currentHour, minute: 0, second: 0, millisecond: 0});
                }

                if (personsItem != false) {
                  personsItem.find("option").each(function () {
                    var optionValue = $(this).val();
                    optionValue = parseInt(optionValue.replace("personcount_", ""));
                    if (optionValue > remainingByHour[currentHour]) {
                      $(this).css("display", "none").removeAttr('selected');
                      this.selected = false;
                    } else {
                      $(this).css("display", "block");
                    }
                  });
                  console.log("SELECTED: " + personsItem.val());
                  w._checkSlideButtons(o.OPTIONSLIDER[0].slick)
                }

                o.CALENDAR.data("DateTimePicker").defaultDate(selectedDate);
                o.CALENDAR.removeAttr("data-disabled");
              } else {
                o.CALENDAR.attr("data-disabled", "true");

              }

              console.log(selectedDate);
              var errorControl = o.CALENDAR.find(".errormsgtime");
              if (errorControl.length == 0) {
                errorControl = $("<h3 style='color:red;text-align: center;' class='errormsgtime'></h3>");
                o.CALENDAR.append(errorControl)
              }

              if (availableHours.length > 0) {
                o.CALENDAR.find(".timepicker").show();
                errorControl.html(error);
                o.CALENDAR.data("DateTimePicker").enabledHours(availableHours);

              } else {
                o.CALENDAR.find(".timepicker").hide();
                //   o.CALENDAR.prop('disabled', true);

                errorControl.html(error);
              }
              o.OPTIONSLIDER.slick('setPosition');
              o.OPTIONSLIDER.slick('resize');
              w._checkSlideButtons(o.OPTIONSLIDER[0].slick)


            }
            w._hideLoader(o.CALENDAR.find(".timepicker"));
            //}

          }
        });
      },


      _getValidDates: function (viewDate) {
        var date = new Date(viewDate);
        var startofmonth = new Date(date.getFullYear(), date.getMonth(), 1);
        var endofmonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        startofmonth.setDate(startofmonth.getDate() - $(".datepicker .old.day").length);
        endofmonth.setDate(endofmonth.getDate() + $(".datepicker .new.day").length);

        console.log("Start : " + startofmonth);
        console.log("End : " + endofmonth);

        var datestringStart = startofmonth.getDate() + "." + (startofmonth.getMonth() + 1) + "." + startofmonth.getFullYear();
        var datestringEnd = endofmonth.getDate() + "." + (endofmonth.getMonth() + 1) + "." + endofmonth.getFullYear();

        return {
          "startDate": datestringStart,
          "endDate": datestringEnd
        };

      },


      destroy: function () {
        $.Widget.prototype.destroy.call(this);
      },
      setOption: function (key, value, updateView) {
      }
    });
  $("body").ShopWidget({});
})(jQuery, window, document);

